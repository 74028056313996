import Vue, { getCurrentInstance, ref } from 'vue';
import store from '../store';
import Router from 'vue-router';
import routes from './routes';
import { Message } from 'element-ui';
import { getToken, removeToken } from '@/util/cookie';
import { getMenu, getUserInfo, featchTmpAuthPhoneList } from '@/api/autho';
import { getUserMenu } from '@/api/autho/menu';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, resolve, reject) {
  if (resolve || reject) return originalPush.call(this, location, resolve, reject);
  return originalPush.call(this, location).catch((e) => {});
};

Vue.use(Router);

const router = new Router({
  base: '/insight360/',
  routes,
  mode: 'history',
});

let isFirstLoad = true;

const formatMenuField = (menu) => {
  return {
    id: menu.id + '',
    name: menu.name + '',
    path: menu.path + '',
  };
};
const findTreeItem = (tree, path) => {
  function findNodeByPath(tree, path) {
    for (let node of tree) {
      if (node.path === path) {
        return node; // 找到了目标节点
      }
      if (node.children && node.children.length > 0) {
        const foundNode = findNodeByPath(node.children, path);
        if (foundNode) {
          return foundNode; // 子树中找到了目标节点
        }
      }
    }
    return null; // 没有找到目标节点
  }
  return findNodeByPath(tree, path);
};

const getNewMenus = () => {
  return getUserMenu().then((res) => {
    if (res.data.succ) {
      const data = res?.data?.result?.find((el) => el.path == '/admin')?.children ?? [];
      const arrTree = treeToArr(data);
      const permissions = arrTree.filter((el) => el.type == 'permission').map((el) => el.path);
      const permissionsRouters = arrTree.filter((el) => el.type == 'menu').map((el) => el.path);
      store.commit('setPermissions', permissions);
      store.commit('setPermissionsRouters', permissionsRouters);
      store.commit('setNewMenus', handleTreePath(data));
      return data;
    } else {
      return false;
    }
  });
};

const treeToArr = (data, pid, res = []) => {
  data.forEach((el) => {
    res.push(el);
    if (el.children && el.children.length) {
      treeToArr(el.children, el.id, res);
    }
  });
  return res;
};

const getTmpAuthPhoneList = () => {
  return featchTmpAuthPhoneList().then((res) => {
    if (res.data.succ === 1) {
      store.commit('setTmpAuthPhoneList', res.data.result ?? []);
      return res.data.result;
    } else {
      return false;
    }
  });
};

const getUser = () => {
  return getUserInfo().then((res) => {
    if (!res.data.code) {
      store.commit('SET_STORE_INFO', res.data.data.store);
      store.commit('SET_TENANT_INFO', res.data.data.tenant);
      store.commit('SET_USERINFO_INFO', res.data.data.userInfo || {});
      store.commit('SET_ROLE_INFO', res.data.data.roles || {});
      return res.data.data;
    } else {
      Message.error(res?.data?.message ?? '获取用户信息失败');
      return false;
    }
  });
};

const getTree = () => {
  const treeListData = store.state.BIV2.treeListData;
  // console.log('treeListData===>', treeListData, store);
  store.dispatch('BIV2/getListData');
};

const handleTreePath = (data, ids = [0]) => {
  data.forEach((el) => {
    const children = el.children.filter((item) => item.type == 'menu');
    Object.assign(el, { ids: ids.concat([el.id]), children });
    if (children && children.length) {
      handleTreePath(children, el.ids);
    }
  });
  return data;
};

//查找菜单
// const findMenuInfo = (key, value, not, source, childrenKey = 'children') => {
//   let reslute = {};
//   const handleFind = (menuItem, modules) => {
//     menuItem.find((item) => {
//       if (Object.keys(reslute).length) return;
//       if (typeof item[key] === 'string') {
//         item[key] = item[key].trim();
//       }
//       let flag = not ? item[key] != value : item[key] == value;
//       if (flag) {
//         //判断isAuthed属性的时候需要判断targetUrl
//         if (!item.targetUrl || item.targetUrl == '') {
//           item[childrenKey] && handleFind(item[childrenKey], modules);
//         } else {
//           reslute = item;
//         }
//       } else if (item[childrenKey]) {
//         handleFind(item[childrenKey], modules);
//       }
//     });
//   };

//   for (let modules in source) {
//     if (Object.keys(reslute).length) return reslute;
//     let menuItem = source[modules];
//     handleFind(menuItem, modules);
//   }
//   return reslute;
// };

const getAuthMeun = (data) => {
  let targetAuthUrl;
  getUrl(data);
  return targetAuthUrl;
  function getUrl(data) {
    data.some((el) => {
      if (el.children && el.children.length) {
        getUrl(el.children);
      } else {
        if (!targetAuthUrl) targetAuthUrl = el.path;
      }
    });
  }
};

router.beforeEach((to, from, next) => {
  const whiteList = ['/login', '/404']; // 定义白名单
  store.commit('WhetherTree_updata', false);
  store.commit('TREE_ALL', 0);
  // store.commit("TREE_ALL", 230)
  let hasToken = getToken();

  // 拦截旧域名
  const oldDomainWhiteList = ['/oldDomain']; // 定义白名单
  const origin = window.location.origin;
  if (origin.includes('bjddlm.com')) {
    if (oldDomainWhiteList.indexOf(to.path) > -1) {
      next();
    } else {
      next('/oldDomain');
    }
    return;
  }

  if (hasToken) {
    if (isFirstLoad) {
      Promise.all([
        getNewMenus(),
        getUser(),
        /*loadCommonData(), */ getTree(),
        getTmpAuthPhoneList(),
      ])
        .then(([menuData, userData]) => {
          if (!menuData || !menuData.length) {
            Message.error('暂无应用菜单权限');
            return;
          }
          if (menuData && userData) {
            isFirstLoad = false;

            // 首次登录进来的时候，需要初始化tab栏
            let menu = findTreeItem(menuData, to.path);
            if (menu) {
              let formatMenuItem = formatMenuField(menu);
              let itemMenu = JSON.parse(JSON.stringify(formatMenuItem));

              let menuId = store.state.menu.menuData.menuTab.map((item) => item.id);
              if (menuId.indexOf(itemMenu.id) == -1) {
                store.state.menu.menuData.menuTab.push(itemMenu);
                localStorage.setItem('menuTabB', JSON.stringify(store.state.menu.menuData.menuTab));
                store.state.menu.menuData.menuIdB = itemMenu.id;
                localStorage.setItem('menuIdB', itemMenu.id);
              }
            }

            next();
            // if (store.state.menu.permissionsRouters.includes(to.path)) {
            //   next();
            // } else {
            //   next(getAuthMeun(menuData))
            // }
          } else {
            next('/login');
          }
        })
        .catch((err) => {
          console.log('catch--', err);
          removeToken();
          localStorage.clear();
          sessionStorage.clear();
          next('/login');
        });
    } else {
      next();
    }
  } else {
    //   没有token的情况下
    if (whiteList.indexOf(to.path) > -1) {
      //  表示要去的地址在白名单
      next();
    } else {
      if (to.path === '/workflow/todoList/detail') {
        sessionStorage.setItem('defaultPagePath', to.fullPath);
      }
      next('/login');
    }
  }
});

router.onError((error) => {
  const pattern = /Loading chunk (.*)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});

// 导出useRouter
export function useRouter() {
  const vm = getCurrentInstance().proxy;
  if (!vm) throw new Error('useRouter must be called in setup function');
  const routerRef = ref(vm.$router);
  return {
    routerRef,
  };
}
// 导出useRoute
export function useRoute() {
  const vm = getCurrentInstance().proxy;
  if (!vm) throw new Error('useRoute must be called in setup function');
  const routeRef = ref(vm.$route);
  return {
    routeRef,
  };
}

export default router;
